<template>
  <transition name="slide">
    <div class="login">
      <div class="logo-bg">
        <!-- <img class="logo" src="../../images/bgc/logo.png" /> -->
      </div>
      <div class="form-container">
        <span class="form-header">{{ $t('Login.中建材海外') }}ERP</span>
        <ValidationObserver ref="observer"
                            tag="form"
                            v-slot="{ invalid }"
                            @submit.prevent="login">
          <van-cell-group class="login-from">
            <ValidationProvider rules="required"
                                :name="'用户名'"
                                v-slot="{ errors }">
              <van-field v-model="loginName"
                         required
                         clearable
                         border
                         :placeholder="$t('Login.请输入用户名')">
                <template slot="left-icon">
                  <img class="form-img"
                       src="../../images/login/u14.png"/>
                </template>
              </van-field>
              <div class="errormessage">{{ errors[0] }}</div>
            </ValidationProvider>

            <ValidationProvider rules="required"
                                :name="'密码'"
                                v-slot="{ errors }">
              <van-field v-model="password"
                         clearable
                         required
                         border
                         type="password"
                         :placeholder="$t('Login.请输入密码')">
                <template slot="left-icon">
                  <img class="form-img"
                       src="../../images/login/u13.png"/>
                </template>
              </van-field>
              <div class="errormessage">{{ errors[0] }}</div>
            </ValidationProvider>

            <ValidationProvider
              :name="'验证码'"
              v-if="false"
              v-slot="{ errors }">
              <van-field v-model="captcha"
                         clearable
                         required
                         border
                         style="line-height: 30px;"
                         :placeholder="$t('Login.请输验证码')"
                         :error-message="captchaErr">
                <div slot="button"
                     class="divIdentifyingCode"
                     style="height: 30px;"
                     @click="getVerifyImg()">
                  <img :src="verifyImg"
                       style="height:30px; width: 100px; cursor: pointer;"
                       :alt="$t('Login.点击更换')"
                       :title="$t('Login.点击更换')"/>
                </div>
              </van-field>
              <div style="color:red;font-size:12px;margin-bottom: 20px;text-align: left" v-show="errMsg">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{errMsg}}</div>
              <div class="errormessage">{{ errors[0] }}</div>
            </ValidationProvider>
          </van-cell-group>
          <div>
            <van-button type="primary"
                        size="small"
                        class="login-btn"
                        :loading="loading">{{ $t('Login.登录') }}
            </van-button>
          </div>
        </ValidationObserver>
      </div>

    </div>
  </transition>
</template>

<script>
    import {Toast} from 'vant';
    import {JSEncrypt} from 'jsencrypt';

    export default {
        data() {
            return {
                loginName: '',
                password: '',
                captcha: '',
                verifyImg: '',
                loginNameErr: '',
                passwordErr: '',
                captchaErr: '',
                captchaId: '',
                loading: false,
                value: '',
                errMsg: '',
                publicKey:
                    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDYJilhqeNghR9QitNeTY7TA8z7oNV3jKs45xE30OmBfaWrK2Lc2MfHh2lQKFoj7MxeU8jbTVI1OdJ16uF32AvFmbiZZEKWiT2RdI0G2mV6sFAw2iN61dJm3ZQj8hzrmSsnKuV5FX+rcXWsJyDPsvm0uuPDLgCgg5fvE9QraRtduQIDAQAB'
            };
        },
        methods: {
            async login() {
                const valid = await this.$refs.observer.validate().then(result => result);
                if (!valid) return;
                this.$myHttp({
                    method: 'post',
                    url: '/microarch/auth/login',
                    data: {
                        loginName: this.loginName,
                        password: this.encryptedData(this.publicKey, this.password),
                        captcha: this.captcha,
                        captchaId: this.captchaId,
                        terminal: 'mobile'
                    }
                }).then(res => {
                    var token = res.data.token;
                    if (!token) {
                        // this.errMsg = res.data.errMsg;
                        this.captchaErr = res.data.errMsg;
                    } else {
                        sessionStorage.token = res.data.token;
                        // sessionStorage.userName = this.userName;
                        sessionStorage.loginName = this.loginName;
                        this.findUserInfo();
                        this.setCookie(this.loginName, this.password)
                    }
                })
                    .catch(error => {
                        //Toast.fail(error.data);
                        this.errMsg = error.data;
                        this.loading = false;
                    });
            },
            setCookie(name, value) {
                window.document.cookie = "userName" + "=" + name + ";path=/;expires=";
                window.document.cookie = "userPwd" + "=" + value + ";path=/;expires=";
            },
            getCookie: function () {
                if (document.cookie.length > 0) {
                    var arr = document.cookie.split('; ');
                    for (var i = 0; i < arr.length; i++) {
                        var arr2 = arr[i].split('=');
                        if (arr2[0] === 'userName') {
                            this.loginName = arr2[1];
                        } else if (arr2[0] === 'userPwd') {
                            this.password = arr2[1];
                        }
                    }
                }
            },
            getSysDict() {
                this.$myHttp({
                    method: 'POST',
                    headers: {Authorization: 'Sys ' + sessionStorage.getItem('token')},
                    url: '/microarch/sys/sysDictHead/find',
                    data: {
                        entity: {
                            lang: 'zh_CN'
                        }
                    }
                }).then(res => {
                    localStorage.setItem('sysDict', JSON.stringify(res.data.rows));
                    this.loading = false;
                    this.$router.push({
                        name: 'Home'
                    });
                });
            },
            findUserInfo() {
                this.$myHttp({
                    method: 'POST',
                    headers: {Authorization: 'Sys ' + sessionStorage.getItem('token')},
                    url: '/microarch/sys/sysuser/findUserInfo',
                    data: {entity: {loginName: this.loginName}}
                }).then(res => {
                    sessionStorage.setItem('cname', res.data.entity.cname);
                    sessionStorage.setItem('userName', res.data.entity.userName);
                    localStorage.setItem('userInfo', JSON.stringify(Object.assign(res.data.entity, res.data.ext)))
                    this.sendWxCode(res.data.entity.id, this.$route.query.code);
                    this.getSwitchAccount(res.data.entity);
                    this.getSysDict();
                });
            },
            getSwitchAccount(userInfo) {
                const url = '/microarch/sys/sysuser/findSwitchAccount';
                const entity = {
                    lang: this.$i18n.locale,
                    id: userInfo.id,
                    accountType: userInfo.accountType,
                    pid: userInfo.pid
                }
                return this.$myHttp.post(url, { entity }).then(resp => {
                    const accounts = resp.data.ext;
                    const usernames = [...accounts.map(acc => acc.userName), userInfo.userName].join(',');
                    localStorage.setItem('usernames', usernames);
                    return accounts;
                })
            },
            sendWxCode(id, code) {
              if (!id || !code) {
                  console.log('非微信登录');
                  return;
              }
              const url = '/microarch/sys/sysuser/updateOpenIdByCodeForUser'
              const data = { id, code }
              this.$myHttp.post(url, data).then(res => {
                  console.log('更新openId成功');
              }).catch(err => {
                  console.log('更新openId失败', err);
              })
            },
            encryptedData(publicKey, data) {
                // 新建JSEncrypt对象
                let encryptor = new JSEncrypt();
                // 设置公钥
                encryptor.setPublicKey(publicKey);
                // 加密数据
                return encryptor.encrypt(data);
            },

            // 验证
            getVerifyImg() {
                this.$myHttp({
                    method: 'get',
                    url: '/microarch/captcha/generate',
                    responseType: 'arraybuffer'
                })
                    .then(res => {
                        console.log(res);
                        this.captchaId = res.headers.captchaid;
                        return (
                            'data:image/png;base64,' +
                            btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))
                        );
                    })
                    .then(data => {
                        this.verifyImg = data;
                    });
            },
            reset() {
                console.log('reset');
            }
        },
        mounted() {
            localStorage.clear();
            sessionStorage.clear();
            this.getVerifyImg();
            this.getCookie();
        },
        created() {
        }
    };
</script>

<style lang="less" scoped>
  .van-cell:after {
    width: 0;
    height: 1px;
    margin-left: 10px;
    background: #1373ff;
    position: absolute;
    top: 100%;
    left: 50%;
    transition: all 0.8s;
  }

  .van-cell:hover:after {
    left: 0%;
    width: 90%;
  }
</style>
<style lang="stylus" scoped>
  .login
    width 100%
    height 100%
    text-align center
    background-color rgba(239, 249, 255, 1)
    background-repeat no-repeat
    background-size cover
    overflow hidden

    .logo-bg
      width 100%
      height 200px
      background url('../../images/bgc/logo-bg.png')
      background-size cover

    .logo
      width 172px
      height 58px
      margin-top 20%
      border-width 0
      background-size cover

    .func
      padding 10px 16px
      font-size 14px

    .login-btn
      width 80%
      background linear-gradient(99.7412285283948deg, rgba(28, 196, 255, 1) 10%, rgba(0, 153, 236, 1) 100%)
      border none
      border-radius 20px
      line-height 36px
      height 36px
      font-size 14px
      color #FFFFFF
      bottom -20px
      left 30px
      position absolute

  .form-container
    border-width 0
    position relative
    margin-top -30px
    /*height 340px*/
    margin-left 30px
    margin-right 30px
    background inherit
    background-color rgba(255, 255, 255, 1)
    border none
    border-radius 10px
    -moz-box-shadow 0 5px 10px rgba(5, 102, 163, 0.156862745098039)
    -webkit-box-shadow 0 5px 10px rgba(5, 102, 163, 0.156862745098039)
    box-shadow 0 5px 10px rgba(5, 102, 163, 0.156862745098039)

  .form-header
    font-size 24px
    color #3E4A59
    border-width 0
    float left
    margin-top 40px
    margin-bottom 30px
    margin-left 40px

  .form-img
    width 20px
    height 20px
    margin-right 20px
    margin-left 20px

  .login-from
    text-align left
    padding 10px
    margin 0 auto
    background transparent

    .btn
      text-align center
      margin-top 10px

  .login-from::after
    border-width 0

  .van-cell
    background transparent

  .slide-enter-active, .slide-leave-active
    transition all 0.5s

  .slide-enter, .slide-leave-to
    opacity 0
    transform translate3d(100%, 0, 0)

  .van-cell--required::before
    display none
</style>
