var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide"}},[_c('div',{staticClass:"login"},[_c('div',{staticClass:"logo-bg"}),_c('div',{staticClass:"form-container"},[_c('span',{staticClass:"form-header"},[_vm._v(_vm._s(_vm.$t('Login.中建材海外'))+"ERP")]),_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('van-cell-group',{staticClass:"login-from"},[_c('ValidationProvider',{attrs:{"rules":"required","name":'用户名'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('van-field',{attrs:{"required":"","clearable":"","border":"","placeholder":_vm.$t('Login.请输入用户名')},model:{value:(_vm.loginName),callback:function ($$v) {_vm.loginName=$$v},expression:"loginName"}},[_c('template',{slot:"left-icon"},[_c('img',{staticClass:"form-img",attrs:{"src":require("../../images/login/u14.png")}})])],2),_c('div',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":'密码'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('van-field',{attrs:{"clearable":"","required":"","border":"","type":"password","placeholder":_vm.$t('Login.请输入密码')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}},[_c('template',{slot:"left-icon"},[_c('img',{staticClass:"form-img",attrs:{"src":require("../../images/login/u13.png")}})])],2),_c('div',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(false)?_c('ValidationProvider',{attrs:{"name":'验证码'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('van-field',{staticStyle:{"line-height":"30px"},attrs:{"clearable":"","required":"","border":"","placeholder":_vm.$t('Login.请输验证码'),"error-message":_vm.captchaErr},model:{value:(_vm.captcha),callback:function ($$v) {_vm.captcha=$$v},expression:"captcha"}},[_c('div',{staticClass:"divIdentifyingCode",staticStyle:{"height":"30px"},attrs:{"slot":"button"},on:{"click":function($event){return _vm.getVerifyImg()}},slot:"button"},[_c('img',{staticStyle:{"height":"30px","width":"100px","cursor":"pointer"},attrs:{"src":_vm.verifyImg,"alt":_vm.$t('Login.点击更换'),"title":_vm.$t('Login.点击更换')}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errMsg),expression:"errMsg"}],staticStyle:{"color":"red","font-size":"12px","margin-bottom":"20px","text-align":"left"}},[_vm._v(" "+_vm._s(_vm.errMsg))]),_c('div',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1),_c('div',[_c('van-button',{staticClass:"login-btn",attrs:{"type":"primary","size":"small","loading":_vm.loading}},[_vm._v(_vm._s(_vm.$t('Login.登录'))+" ")])],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }